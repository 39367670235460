import React from "react";
import AboutForm from "../../../components/Admin/AboutForm/AboutForm";

import PageWrapper from "../../../components/Admin/PageWrapper/PageWrapper";

const AboutAdmin = () => {
  return (
    <PageWrapper title={"Чому саме ми"}>
      <AboutForm />
    </PageWrapper>
  );
};

export default AboutAdmin;
