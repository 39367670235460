import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import About from "../components/about/About";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import MainSection from "../components/mainSection/MainSection";
import Modal from "../components/modal/Modal";
import Steps from "../components/steps/Steps";
import Trends from "../components/trends/Trends";
import { fetchData } from "../redux/dataSlice";

function MainPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    <div className="App">
      <Header />
      <MainSection />
      <Steps />
      <Trends />
      <About />
      <Modal />
      <Footer />
    </div>
  );
}

export default MainPage;
