import React from "react";
import styles from "./Header.module.scss";
import logo from "../../../icons/logo.png";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/adminSlices/loginSlice";
import { useHistory } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.logo}>
        <img src={logo} alt="comp-logo" />
      </div>
      <div className={styles.logOut}>
        <span
          onClick={() => {
            history.push("/");
            dispatch(logout());
          }}
        >
          Вийти
        </span>
      </div>
    </header>
  );
};

export default Header;
