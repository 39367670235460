import * as Yup from "yup";

export const contactFormValidation = Yup.object().shape({
  instagram: Yup.string().required("Заповніть дане поле*"),
  facebook: Yup.string().required("Заповніть дане поле*"),
  mapLink: Yup.string().required("Заповніть дане поле*"),
  address: Yup.string().required("Заповніть дане поле*"),
  name: Yup.string().required("Заповніть дане поле*"),
  phoneNumber: Yup.string().required("Перевірте данні!"),
});
