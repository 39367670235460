import * as Yup from "yup";

export const loginFormValidation = Yup.object().shape({
  password: Yup.string().required("Заповніть дане поле*"),
  login: Yup.string().required("Заповніть дане поле*"),
});

export const defaultLoginForm = {
  login: "",
  password: "",
};
