import * as Yup from "yup";

const validate = Yup.object({
  name: Yup.string().required("Заповніть дане поле *"),
  phone: Yup.string()
    .min(10, "Номер телефону має складатись з 10 цифр *")
    .max(10, "Номер телефону має складатись з 10 цифр *")
    .required("Заповніть дане поле *"),
});

export default validate;
