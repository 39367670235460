import * as Yup from "yup";

export const mainVideoValidation = Yup.object().shape({});

export const aboutMainSchema = Yup.array().of(
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(13, "Максимальне число символів 13"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(23, "Максимальне число символів 23"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(13, "Максимальне число символів 13"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(23, "Максимальне число символів 23"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(13, "Максимальне число символів 13"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(23, "Максимальне число символів 23"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(13, "Максимальне число символів 13"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(23, "Максимальне число символів 23"),
  })
);
