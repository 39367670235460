import React from "react";
import "./steps.scss";
import ContentWrapper from "../contentWrapper/ContentWrapper";
import step1 from "../../imgs/step1.svg";
import step2 from "../../imgs/step2.svg";
import step3 from "../../imgs/step3.svg";
import step4 from "../../imgs/step4.svg";
import step5 from "../../imgs/step5.svg";
import arrow13 from "../../imgs/arrow1.3.svg";
import arrow24 from "../../imgs/arrow2.4.svg";
import arrow5 from "../../imgs/arrow5.svg";
import backgroundSteps from "../../imgs/backgroundWorking.svg";
import Selector from "../../redux/Selector";
import PhoneButton from "../phoneButton/PhoneButton";

const backgroundArr = [step1, step2, step3, step4, step5];
const workingArrowArr = [arrow13, arrow24, arrow13, arrow24, arrow5];

const Steps = () => {
  const workingData = Selector().workingSection;

  return (
    <ContentWrapper>
      <div className="steps__wrapper">
      <PhoneButton />
        <div className="steps__block__wrapper">
          <div className="steps__title title">Як ми працюємо</div>
          {workingData &&
            workingData.map((item, index) => {
              return (
                <div className={"step__item" + index} key={index}>
                  <img
                    className="step__background"
                    src={backgroundArr[index]}
                    alt={`крок ${index}`}
                  />
                  <div className="step__title item_title">{item.title}</div>
                  <div className="step__description item_description">
                    {item.description}
                  </div>
                  <img
                    className={`step__item__arrow step_item_arrow${index}`}
                    src={workingArrowArr[index]}
                    alt={`стрілка кроку ${index}`}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <img className="steps__background" src={backgroundSteps} alt="фон" />
    </ContentWrapper>
  );
};

export default Steps;
