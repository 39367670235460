import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import {
  openAdminModal,
  postVideoRequest,
} from "../../../../redux/adminSlices/modalSlice";
import { mainVideoValidation } from "../../../../validation/mainValidation";
import AddFile from "../../AddFile/AddFile";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import styles from "./MainVideo.module.scss";

const MainVideo = () => {
  const dispatch = useDispatch();

  const submit = (values) => {
    dispatch(postVideoRequest(values));
  };
  return (
    <Formik
      initialValues={{ videoDesk: "", videoMob: "" }}
      validationSchema={mainVideoValidation}
      onSubmit={submit}
    >
      {(values) => (
        <Form className={styles.formWrapper}>
          <div className={styles.inputsWrapper}>
            <AddFile
              status={"video"}
              values={values}
              title="Відео десктоп"
              name="videoDesk"
              sizes="Розмір
                900х480 рх"
            />

            <AddFile
              status={"video"}
              values={values}
              title="Відео моб."
              name="videoMob"
              sizes="Розмір
                320х480 рх"
            />
          </div>
          <Modal title={"video"} />

          <Button
            onClick={() => {
              dispatch(openAdminModal("video"));
            }}
            disabled={!values.dirty}
            title={"Зберегти"}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MainVideo;
