import React from "react";
import styles from "./PageWrapper.module.scss";
const PageWrapper = (props) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageTitle}>{props.title}</div>
      {props.children}
    </div>
  );
};

export default PageWrapper;
