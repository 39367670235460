import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../../components/Admin/Header/Header";

import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import AboutAdmin from "../../pages/admin/About/About";
import Contact from "../../pages/admin/Contact/Contact";

import HowWeWorks from "../../pages/admin/HowWeWorks/HowWeWorks";

import MainAdmin from "../../pages/admin/MainAdmin/MainAdmin";
import TrendsAdmin from "../../pages/admin/Trends/Trend";
import { adminRoute } from "../routeConfig";
import styles from "./AdminRouter.module.scss";

export const AdminRoute = () => {
  return (
    <div className={styles.adminWrapper}>
      <Header />

      <div className={styles.contentWrapper}>
        <Sidebar />
        <div className={styles.pageContentWrapper}>
          <Switch>
            <Route exact path={adminRoute.Start} component={MainAdmin} />
            <Route exact path={adminRoute.HowWeWorks} component={HowWeWorks} />
            <Route
              exact
              path={adminRoute.FutureTrends}
              component={TrendsAdmin}
            />
            <Route exact path={adminRoute.WhyWeAre} component={AboutAdmin} />
            <Route exact path={adminRoute.Contacts} component={Contact} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
