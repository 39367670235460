import React, { useState, useEffect } from "react";
import { sidebarLinks } from "../../../route/routeConfig";
import styles from "./Sidebar.module.scss";
import { useHistory, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setActiveIdx(
      sidebarLinks.findIndex((elem) => elem.link === location.pathname)
    );
  }, [location]);

  return (
    <nav className={styles.sidebarWrapper}>
      <ul className={styles.navList}>
        {sidebarLinks.map((item, idx) => (
          <li
            className={
              idx === activeIdx ? styles.navItem_active : styles.navItem
            }
            key={"sidebar-" + idx}
            onClick={() => history.push(item.link)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
