import React from "react";
import { useField } from "formik";

export const TextField = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="input__wrapper">
      <input
        className={`input item_description ${
          meta.touched && meta.error ? "invalid" : "correct"
        }`}
        placeholder={meta.touched && meta.error ? meta.error : placeholder}
        {...field}
        {...props}
        // autoComplete="off"
      />
      {field.name === "name" || field.value === "" ? null : (
        <div className="error">{meta.error}</div>
      )}
    </div>
  );
};
