import React from "react";
import "./about.scss";
import ContentWrapper from "../contentWrapper/ContentWrapper";
import logoAbout from "../../icons/logoAbout.svg";
import aboutBackground from "../../imgs/aboutBackground.svg";
import aboutArrow from "../../icons/_.svg";
import Selector from "../../redux/Selector";

const About = () => {
  const aboutData = Selector().aboutSection;

  return (
    <ContentWrapper>
      <div className="about__wrapper">
        <div className="about__title title">Чому саме ми?</div>
        <div className="about__background">
          <img className="about__logo" src={logoAbout} alt="логотип" />
          <img className="about__background__img" src={aboutBackground} alt="фон" />
        </div>
        <div className="about__list__wrapper">
          {aboutData &&
            aboutData.map((item, index) => {
              return (
                <div className="about__item" key={index}>
                  <img className="about__item__arrow" src={aboutArrow} alt="далі" />
                  <div className="about__text__wrapper">
                    <div className="about__item__title item_title">
                      {item.title}
                    </div>
                    <div className="about__item__description item_description">
                      {item.description}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default About;
