import { Form, Formik } from "formik";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTrends } from "../../../redux/selectors/adminSelectors";
import Button from "../Button/Button";
import FormikControl from "../FormikContainer/FormikControl";
import styles from "./TrendForm.module.scss";
import AddFile from "../AddFile/AddFile";
import { Url } from "../../../api/apiAdmin";
import {
  openAdminModal,
  postPhotosRequest,
} from "../../../redux/adminSlices/modalSlice";
import { trendsSchema } from "../../../validation/trendsValidation";
import Modal from "../Modal/Modal";

const TrendsForm = () => {
  const trends = useSelector(getTrends);
  const dispatch = useDispatch();
  const imgSizes = {
    0: "Розмір 540х230 рх",
    1: "Розмір 485х230 рх",
    2: "Розмір 485х230 рх",
    3: "Розмір 390х230 рх",
  };

  const onSubmit = (values) => {
    dispatch(postPhotosRequest(values));
  };

  if (!trends) return null;

  return (
    <Formik
      initialValues={trends}
      validationSchema={trendsSchema}
      onSubmit={onSubmit}
    >
      {(values) => (
        <Form>
          <div className={styles.formWrapper}>
            {trends.map((elem, idx) => (
              <div className={styles.blockWrapper} key={`${idx}`}>
                <div className={styles.fileWrapper}>
                  <AddFile
                    idx={idx}
                    status={"img"}
                    preview={Url + elem.url}
                    values={values}
                    title={`Фото ${idx + 1}`}
                    name={`${idx}.url`}
                    sizes={imgSizes[idx]}
                  />
                </div>
                <div className={styles.inputsWrapper}>
                  <FormikControl
                    control="input"
                    type="text"
                    label={`Назва тренду${idx + 1}`}
                    name={`${idx}.title`}
                  />
                  <FormikControl
                    control="textarea"
                    type="text"
                    label={`Текст ${idx + 1}`}
                    name={`${idx}.description`}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label={`Ціна ${idx + 1}`}
                    name={`${idx}.cost`}
                  />
                </div>
              </div>
            ))}
          </div>

          <Button
            onClick={() => {
              dispatch(openAdminModal("trends"));
            }}
            disabled={!values.dirty || !values.isValid}
            title={"Зберегти"}
          />
          <Modal title={"trends"} />
        </Form>
      )}
    </Formik>
  );
};

export default TrendsForm;
