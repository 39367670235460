import { Field } from "formik";
import React from "react";
import styles from "./AddFile.module.scss";

const Preview = ({ name, idx, status, file, preview }) => {
  switch (status) {
    case "img":
      const smallImg = file[idx]?.url;
      return (
        <img
          src={
            typeof smallImg !== "string"
              ? URL.createObjectURL(smallImg)
              : preview
          }
          alt="preview"
        />
      );
    case "bigImg":
      const map = file[name];
      return (
        <img
          src={typeof map !== "string" ? URL.createObjectURL(map) : preview}
          alt="preview"
        />
      );
    case "video":
      const video = file[name];
      return (
        <span>{video.name ? "..." + video.name.slice(-8) : "+ додати"}</span>
      );
    default:
      return null;
  }
};

const AddFile = (props) => {
  const formikFile = props.values.values;
  return (
    <div className={styles.fileWrapper}>
      <div className={styles.fileTitle}>{props.title}</div>
      <div className={props.big ? styles.fileBoxBig : styles.fileBoxSmall}>
        <label htmlFor={"raised-button-file-" + props.name}>
          {" "}
          <Field
            accept={props.preview ? "image/*" : "video/mp4,video/x-m4v,video/*"}
            id={"raised-button-file-" + props.name}
            value={props.values.value}
            type="file"
            name={props.name}
            onChange={(event) => {
              if (event.currentTarget.files[0]) {
                props.values.setFieldValue(
                  props.name,
                  event.currentTarget.files[0]
                );
              }
            }}
          />
          <Preview
            preview={props.preview}
            name={props.name}
            idx={props.idx}
            status={props.status}
            file={formikFile}
          />
        </label>
      </div>
      <div className={styles.sizesTitle}>{props.sizes}</div>
    </div>
  );
};

export default AddFile;
