import React from "react";

import MainBenefits from "../../../components/Admin/MainForms/MainBenefits/MainBenefits";
import MainVideo from "../../../components/Admin/MainForms/MainVideo/MainVideo";

import PageWrapper from "../../../components/Admin/PageWrapper/PageWrapper";

const MainAdmin = () => {
  return (
    <PageWrapper title={"Головна"}>
      <MainVideo />
      <MainBenefits />
    </PageWrapper>
  );
};

export default MainAdmin;
