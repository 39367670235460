import React, { useEffect, useState } from "react";
import "./phoneButton.scss";
import phoneIcon from "../../icons/phoneIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/dataSlice";

const PhoneButton = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.data.isModalOpen);

  const [phoneBtnPosition, setPhoneBtnPosition] = useState(false);

  const handleNavigation = (y, phoneBtnPosition) => () => {
    if (y > 200 && phoneBtnPosition !== true) {
      setPhoneBtnPosition(true);
      phoneBtnPosition = true;
    } else if (y < 200 && phoneBtnPosition !== false) {
      setPhoneBtnPosition(false);
      phoneBtnPosition = false;
    }
    y = window.scrollY;
  };

  useEffect(() => {
    const handleNavigationWorker = handleNavigation(
      window.scrollY,
      phoneBtnPosition
    );
    window.addEventListener("scroll", handleNavigationWorker);

    return () => {
      window.removeEventListener("scroll", handleNavigationWorker);
    };
  }, [phoneBtnPosition]);

  if (isOpen) return null;
  return (
      <div className={`phone__button__wrapper  ${phoneBtnPosition ? "show" : "hidden"}`}>
              {/* <div className="phone__button__help__wrapper"> */}
                  <button className="phone__button" onClick={() => dispatch(openModal())}>
                    <div
                      className={`phone__icon`}
                    >
                      <img className="phone__icon__img" src={phoneIcon} alt="іконка телефону" />
                    </div>
                  </button>
              {/* </div> */}
      </div>
  );
};

export default PhoneButton;
