export const Url = "https://onyxvin.com";
export const adminUrl = "https://onyxvin.com/api";

export const apiAdmin = {
  loginRequest(values) {
    return fetch(`${adminUrl}/login`, {
      method: "POST",
      body: new URLSearchParams(values),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((res) => {
      if (res.status < 300) {
        return res.text();
      } else {
        return Promise.reject("Ops");
      }
    });
  },
  postVideoRequest(value, token) {
    const { videoDesk, videoMob } = value;
    const promiseArr = [];
    if (videoDesk) {
      promiseArr.push(this.postVideoPCRequest(videoDesk, token));
    }
    if (videoMob) {
      promiseArr.push(this.postVideoMobRequest(videoMob, token));
    }
    return Promise.allSettled(promiseArr).then((res) => {
      if (res.some((elem) => elem.status === "rejected")) {
        return Promise.reject("Ops");
      }
      return res;
    });
  },

  postVideoPCRequest(value, token) {
    const formData = new FormData();
    formData.append("video", value);
    return fetch(`${adminUrl}/upload/videoForPC`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
      body: formData,
    });
  },

  postVideoMobRequest(value, token) {
    const formData = new FormData();
    formData.append("video", value);
    return fetch(`${adminUrl}/upload/videoForPhone`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
      body: formData,
    });
  },
  postPhotosRequest(values, token) {
    return Promise.all(
      values.map((elem, idx) => {
        let formData = null;
        if (typeof elem.url !== "string") {
          formData = new FormData();
          formData.append("photo", elem.url);
        } else {
          formData = new URLSearchParams();
        }
        formData.append("title", elem.title);
        formData.append("description", elem.description);
        formData.append("cost", elem.cost);
        return fetch(`${adminUrl}/upload/photo/${idx}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type":
            //   formData instanceof FormData
            //     ? "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
            //     : "application/x-www-form-urlencoded",
          },
          body: formData,
        });
      })
    );
  },
  patchContactsRequest(value, token) {
    let formData = null;
    if (typeof value.mapImage !== "string") {
      formData = new FormData();
      formData.append("mapImage", value.mapImage);
    } else {
      formData = new URLSearchParams();
    }
    formData.append("instagram", value.instagram);
    formData.append("facebook", value.facebook);
    formData.append("mapLink", value.mapLink);
    formData.append("address", value.address);
    formData.append("phoneNumber", value.phoneNumber);
    formData.append("name", value.name);
    return fetch(`${adminUrl}/saveContacts`, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type":
        //   formData instanceof FormData
        //     ? "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
        //     : "application/x-www-form-urlencoded",
      },
    }).then((res) => res);
  },
  patchAboutRequest(value, token) {
    return fetch(`${adminUrl}/saveAbout`, {
      method: "PATCH",
      body: JSON.stringify(Object.assign({}, value)),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  },
  // patchBenefRequest(value, token) {
  //   return fetch(`${adminUrl}/saveFeatures`, {
  //     method: "PATCH",
  //     body: JSON.stringify(Object.assign({}, value)),
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then((res) => res);
  // },
  patchWorkingRequest(value, token) {
    return fetch(`${adminUrl}/saveWorking`, {
      method: "PATCH",
      body: JSON.stringify(Object.assign({}, value)),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  },
  patchMainBenefits(value, token) {
    return fetch(`${adminUrl}/saveFeatures`, {
      method: "PATCH",
      body: JSON.stringify(Object.assign({}, value)),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  },
};
