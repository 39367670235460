import React from "react";
import "./footer.scss";
import logoFooter from "../../icons/logoFooter.svg";
import instagramIcon from "../../icons/instagram.svg";
import facebookIcon from "../../icons/facebook.svg";
import Selector from "../../redux/Selector";
import API from "../../redux/API";
import Policy from './onyx.pdf';

const Footer = () => {
  const footerData = Selector().contacts;

  return (
    <>
      <div className="footer__wrapper">
        {footerData ? (
          <div className="footer__content">
            <div className="logo__icons__wrapper">
              <div className="footer__logo__icons hoverClass">
                <img src={logoFooter} className="footer__logo__icon" alt="логотип" />
              </div>
              <div className="footer__icons">
                <a href={footerData.instagram}>
                  <img src={instagramIcon} className="icon hover_class" alt="іконка інстаграм" />
                </a>
                <a href={footerData.facebook}>
                  <img src={facebookIcon} className="icon hover_class" alt="іконка фейсбук" />
                </a>
              </div>
            </div>
            <div className="footer__map__phone__wrapper">
              <a className="footer__map__image" href={footerData.mapLink}>
                <img src={API + footerData.mapImage} className="footer__map hover_class" alt="карта" />
              </a>
              <div className="phone__address">
                <div className="footer__address">
                  <a
                    className="footer__address item_description hover_class"
                    href={footerData.mapLink}
                  >
                    {footerData.address}
                  </a>
                </div>
                <a
                  href={`tel: ${footerData ? footerData.phoneNumber : null}`}
                  className="footer__phone item_description hover_class"
                >
                  {footerData.phoneNumber} - {footerData.name}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="under__footer__line"></div>
      <div className="under__footer">
        <a href={Policy} className="conditions hover_class under_footer_hover">Умови користування</a>
        <a href={Policy} className="policy hover_class under_footer_hover">Політика конфіденційності</a>
      </div>
    </>
  );
};

export default Footer;
