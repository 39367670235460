import { Form, Formik } from "formik";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAbout } from "../../../redux/selectors/adminSelectors";
import Button from "../Button/Button";
import FormikControl from "../FormikContainer/FormikControl";
import styles from "./AboutForm.module.scss";

import Modal from "../Modal/Modal";
import {
  openAdminModal,
  patchAboutRequest,
} from "../../../redux/adminSlices/modalSlice";
import { aboutSchema } from "../../../validation/aboutValidation";

const AboutForm = () => {
  const dispatch = useDispatch();
  const about = useSelector(getAbout);

  const onSubmit = (values) => {
    console.log(values);
    dispatch(patchAboutRequest(values));
  };

  if (!about) return null;

  return (
    <Formik
      initialValues={about}
      validationSchema={aboutSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className={styles.formWrapper}>
            {about.map((elem, idx) => (
              <div className={styles.blockWrapper} key={`${idx}`}>
                <FormikControl
                  control="input"
                  type="text"
                  label={`Заголовок ${idx + 1}`}
                  name={`${idx}.title`}
                />
                <FormikControl
                  control="textarea"
                  type="text"
                  label={`Текст ${idx + 1}`}
                  name={`${idx}.description`}
                />
              </div>
            ))}
          </div>
          <Modal title={"about"} />
          <Button
            onClick={() => {
              dispatch(openAdminModal("about"));
            }}
            disabled={!formik.dirty || !formik.isValid}
            title={"Зберегти"}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AboutForm;
