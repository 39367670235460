import * as Yup from "yup";

export const workingSchema = Yup.array().of(
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(25, "Максимальне число символів 25"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(120, "Максимальне число символів 120"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(25, "Максимальне число символів 25"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(120, "Максимальне число символів 120"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(25, "Максимальне число символів 25"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(120, "Максимальне число символів 120"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(25, "Максимальне число символів 25"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(120, "Максимальне число символів 120"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(25, "Максимальне число символів 25"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(120, "Максимальне число символів 120"),
  })
);
