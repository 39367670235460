import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiAdmin } from "../../api/apiAdmin";
import { fetchData } from "../dataSlice";

export const postPhotosRequest = createAsyncThunk(
  "modal/postPhotosRequest",
  async (values, context) => {
    const token = context.getState().adminLogin.accessToken;
    const res = await apiAdmin.postPhotosRequest(values, token);
    console.log(res);
    if (res.some((response) => !response.ok))
      return Promise.rejected("modal/postPhotosRequest");
    context.dispatch(fetchData());
    return res;
  }
);
export const patchWorkingRequest = createAsyncThunk(
  "modal/patchWorkingRequest",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin
      .patchWorkingRequest(value, token)
      .then(() => context.dispatch(fetchData()));
  }
);

export const patchAboutRequest = createAsyncThunk(
  "modal/patchAboutRequest",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin
      .patchAboutRequest(value, token)
      .then(() => context.dispatch(fetchData()));
  }
);
export const patchContactsRequest = createAsyncThunk(
  "modal/patchContactsRequest",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin
      .patchContactsRequest(value, token)
      .then(() => context.dispatch(fetchData()));
  }
);

export const postVideoRequest = createAsyncThunk(
  "modal/patchVideoPCRequest",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin
      .postVideoRequest(value, token)
      .then(() => context.dispatch(fetchData()));
  }
);

export const patchMainBenefits = createAsyncThunk(
  "modal/patchMainBenefits",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin
      .patchMainBenefits(value, token)
      .then(() => context.dispatch(fetchData()));
  }
);
export const patchBenefRequest = createAsyncThunk(
  "modal/patchAboutRequest",
  (value, context) => {
    const token = context.getState().adminLogin.accessToken;
    return apiAdmin.patchBenefRequest(value, token);
  }
);

const modal = createSlice({
  name: "modal",
  initialState: {
    show: "", //mainVideo, mainBenef, works, trends, about, contact
    pending: false,
    fulfilled: false,
    rejected: false,
  },
  reducers: {
    openAdminModal(state, action) {
      state.show = action.payload;
    },
    closeAdminModal(state) {
      state.show = "";
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
    },
  },
  extraReducers: {
    //-------------------------------------
    [postPhotosRequest.pending]: (state) => {
      state.pending = true;
    },
    [postPhotosRequest.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [postPhotosRequest.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
    //-------------------------------------
    [patchWorkingRequest.pending]: (state) => {
      state.pending = true;
    },
    [patchWorkingRequest.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [patchWorkingRequest.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
    //-------------------------------------
    [patchAboutRequest.pending]: (state) => {
      state.pending = true;
    },
    [patchAboutRequest.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [patchAboutRequest.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
    //-------------------------------------
    [patchContactsRequest.pending]: (state) => {
      state.pending = true;
    },
    [patchContactsRequest.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [patchContactsRequest.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
    //-------------------------------------

    [postVideoRequest.pending]: (state) => {
      state.pending = true;
    },
    [postVideoRequest.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [postVideoRequest.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
    //-------------------------------------
    [patchMainBenefits.pending]: (state) => {
      state.pending = true;
    },
    [patchMainBenefits.fulfilled]: (state) => {
      state.pending = false;
      state.fulfilled = true;
    },
    [patchMainBenefits.rejected]: (state) => {
      state.pending = false;
      state.rejected = true;
    },
  },
});
export const { openAdminModal, closeAdminModal, setArgs, setCb } =
  modal.actions;
export default modal.reducer;
