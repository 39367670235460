import * as Yup from "yup";

export const trendsSchema = Yup.array().of(
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(30, "Максимальне число символів 30"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(250, "Максимальне число символів 250"),
    cost: Yup.string()
      .required("Заповніть дане поле*")
      .max(35, "Максимальне число символів 35"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(30, "Максимальне число символів 30"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(250, "Максимальне число символів 250"),
    cost: Yup.string()
      .required("Заповніть дане поле*")
      .max(35, "Максимальне число символів 35"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(30, "Максимальне число символів 30"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(250, "Максимальне число символів 250"),
    cost: Yup.string()
      .required("Заповніть дане поле*")
      .max(35, "Максимальне число символів 35"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(30, "Максимальне число символів 30"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(250, "Максимальне число символів 250"),
    cost: Yup.string()
      .required("Заповніть дане поле*")
      .max(35, "Максимальне число символів 35"),
  })
);
