import React from "react";
import "./NotFound.scss";
import PageNotFoundImg from "../../imgs/404.svg";
import Button from "../button/Button";

const NotFound = () => {
  return (
    <div className="page__not__found__wrapper">
      <img className="page__not__found__img" src={PageNotFoundImg} alt="сторінку не знайдено" />
      <div className="page__not__found__text__wrapper">
        <div className="page__not__found__title">Уупс :(</div>
        <div className="page__not__found__text">
          Щось пішло не так. Сторінку не знайдено
        </div>
        <div className="page__not__found__btn">
          <Button text="На головну" click="goToMain"/>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
