import React from "react";
import styles from "./Modal.module.scss";
import closeLogo from "../../../icons/x.png";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeAdminModal } from "../../../redux/adminSlices/modalSlice";
import { getModalState } from "../../../redux/selectors/adminSelectors";

const ModalContent = ({ handleClose, status }) => {
  if (status.fulfilled || status.rejected || status.pending) {
    return (
      <>
        <div className={styles.modalText}>
          {status.fulfilled && "Данні були успішно збережені."}
          {status.rejected && "Щось сталось!"}
          {status.pending && "Завантаженя!"}
        </div>
        {!status.pending && (
          <div className={styles.btnBlock}>
            <Button title={"ОК"} onClick={() => handleClose()} />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className={styles.modalText}>{"Редагувати даний обєкт?"}</div>
        <div className={styles.btnBlock}>
          <Button title={"Так"} type="submit" red />
          <Button title={"Ні"} onClick={() => handleClose()} />
        </div>
      </>
    );
  }
};

const Modal = (props) => {
  const modal = useSelector(getModalState);
  const dispatch = useDispatch();
  const handleClose = () => {
    if (!modal.pending) {
      dispatch(closeAdminModal());
    }
  };

  if (!modal.show || !props.title || props.title !== modal.show) return null;

  return (
    <div
      id={"modalWrapper"}
      onClick={(e) => (e.target.id === "modalWrapper" ? handleClose() : null)}
      className={styles.modalWrapper}
    >
      <div className={styles.modal}>
        <div className={styles.close}>
          <img onClick={() => handleClose()} src={closeLogo} alt="close" />
        </div>
        <div className={styles.modalContent}>
          <ModalContent status={modal} handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
