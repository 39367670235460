import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWorkingSection } from "../../../redux/selectors/adminSelectors";
import Button from "../Button/Button";

import FormikControl from "../FormikContainer/FormikControl";

import styles from "./HowWeWorksForm.module.scss";

import { workingSchema } from "../../../validation/howWeWorkValidation";
import Modal from "../Modal/Modal";
import {
  openAdminModal,
  patchWorkingRequest,
} from "../../../redux/adminSlices/modalSlice";

const HowWeWorksForm = () => {
  const dispatch = useDispatch();
  const workingSection = useSelector(getWorkingSection);

  const onSubmit = (values) => {
    dispatch(patchWorkingRequest(values));
  };

  if (!workingSection) return null;

  return (
    <Formik
      initialValues={workingSection}
      validationSchema={workingSchema}
      onSubmit={onSubmit}
    >
      {(values) => (
        <Form>
          <Modal />

          <div className={styles.formWrapper}>
            {workingSection &&
              workingSection.map((elem, idx) => (
                <div className={styles.blockWrapper} key={`${idx}`}>
                  <FormikControl
                    control="input"
                    type="text"
                    label={`Заголовок ${idx + 1}`}
                    name={`${idx}.title`}
                  />
                  <FormikControl
                    control="textarea"
                    type="text"
                    label={`Текст ${idx + 1}`}
                    name={`${idx}.description`}
                  />
                </div>
              ))}
          </div>
          <Modal title={"works"} />
          <Button
            onClick={() => {
              dispatch(openAdminModal("works"));
            }}
            disabled={!values.dirty || !values.isValid}
            title={"Зберегти"}
          />
        </Form>
      )}
    </Formik>
  );
};

export default HowWeWorksForm;
