import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { transformRespons } from "../utils/transformRespons";

export const fetchData = createAsyncThunk("test/fetchData", async function () {
  const response = await fetch("https://onyxvin.com/api/info");

  const data = await response.json();
  return transformRespons(data);
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    status: null,
    data: {},
    isModalOpen: false,
  },
  reducers: {
    openModal(state, action) {
      state.isModalOpen = !state.isModalOpen;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.status = "loading";
    },
    [fetchData.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
    },
  },
});

export const { openModal, showAdminModal } = dataSlice.actions;

export default dataSlice.reducer;
