import "./App.scss";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchData } from "./redux/dataSlice";
import { Route, Switch } from "react-router-dom";

import { AdminRoute } from "./route/AdminRouter/AdminRouter";
import { route } from "./route/routeConfig";
import { LoginForm } from "./pages/admin/Login/LoginForm";
import { getToken } from "./redux/selectors/adminSelectors";
import MainPage from "./pages/MainPage";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    <Switch>
      {token ? (
        <Route path={route.admin}>
          <AdminRoute />
        </Route>
      ) : (
        <Route path={route.admin} component={LoginForm} />
      )}
      <Route exact path="/" component={MainPage} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default App;
