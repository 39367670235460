const API = "https://onyxvin.com";

export function sendModalData(values) {
  try {
    const data = new URLSearchParams(values);
    fetch(`${API}/api/sendOrder`, {
      method: "POST",
      body: data,
    });
  } catch (error) {
    console.log(error);
  }
}

export default API;
