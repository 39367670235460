export const transformRespons = (data) => {
  data.trends = [data.photo0, data.photo1, data.photo2, data.photo3];
  delete data.photo0;
  delete data.photo1;
  delete data.photo2;
  delete data.photo3;
  data.mainSection = [
    data.features[0],
    data.features[1],
    data.features[2],
    data.features[3],
  ];
  delete data.features;
  data.workingSection = [
    data.working[0],
    data.working[1],
    data.working[2],
    data.working[3],
    data.working[4],
  ];
  delete data.working;
  data.aboutSection = [
    data.about[0],
    data.about[1],
    data.about[2],
    data.about[3],
  ];
  delete data.about;
  // data.contacts.phoneNumber = data.contacts.phoneNumber.substring(0, 3) + " " + data.contacts.phoneNumber.substring(3, 6) + " " + data.contacts.phoneNumber.substring(6, 8) + " " + data.contacts.phoneNumber.substring(8, 10) + " " + data.contacts.phoneNumber.substring(10, 13)
  return data;
};
