import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginSlice from "./adminSlices/loginSlice";
import modalSlice from "./adminSlices/modalSlice";
import dataSlice from "./dataSlice";

const rootReducer = combineReducers({
  data: dataSlice,
  adminLogin: loginSlice,
  modal: modalSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
