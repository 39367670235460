import React from "react";
import "./mainSection.scss";
import Button from "../button/Button";
import logoMainSection from "../../icons/logoMainSection.svg";
import Selector from "../../redux/Selector";
import API from "../../redux/API";
import useMedia from "use-media";
import { Spinner } from "./spinner/Spinner";

const MainSection = () => {
  const mainSectionData = Selector().mainSection;
  const videoForPC = Selector().videoForPC;
  const videoForPhone = Selector().videoForPhone;

  const [isVideoLoading, setIsVideoLoading] = React.useState(true);

  const isMobile = useMedia({ maxWidth: 667 });

  React.useEffect(() => {
    if (!isVideoLoading) setIsVideoLoading(true);
  // eslint-disable-next-line
  }, [isMobile]);

  return (
    <div className="main__section__wrapper">
      {isMobile ? (
        <div className="main__section__video__phone">
          <video
            key={videoForPhone && API + videoForPhone}
            autoPlay
            muted
            loop={true}
            playsInline
            poster={API + "/public/video/framePhone_1.jpg"}
            onCanPlay={() => setIsVideoLoading(false)}
          >
            {videoForPhone && <source src={API + videoForPhone} />}
          </video>
          {isVideoLoading && <Spinner wrapped />}
        </div>
      ) : (
        <div className="main__section__video__PC">
          <video
            key={videoForPC && API + videoForPC}
            autoPlay
            muted
            loop={true}
            playsInline
            poster={API + "/public/video/framePC_1.jpg"}
            onCanPlay={() => setIsVideoLoading(false)}
          >
            {videoForPC && <source src={API + videoForPC} />}
          </video>
          {isVideoLoading && <Spinner wrapped />}
        </div>
      )}
      <div className="main__section__list__wrapper">
        <ul className="main__section__list">
          {mainSectionData &&
            mainSectionData.map((item, index) => {
              return (
                <div className="main__section__list__item__wrapper" key={index}>
                  <div className="main__section__list__item__background">
                    {item.title}
                  </div>
                  <li className="main__section__list__item">
                    {item.description}
                  </li>
                </div>
              );
            })}
        </ul>
        <div className="main__section__button">
          <Button click="openModal" text="Виклик майстра" />
        </div>
      </div>
      <div className="background__logo__wrapper">
        <img
          className="background__logo__icon"
          src={logoMainSection}
          alt="фоновий логотип"
        />
      </div>
    </div>
  );
};

export default MainSection;
