import React from "react";
import ContactForm from "../../../components/Admin/ContactForm/ContactForm";

import PageWrapper from "../../../components/Admin/PageWrapper/PageWrapper";

const Contact = () => {
  return (
    <PageWrapper title={"Контакти і соцмережі"}>
      <ContactForm />
    </PageWrapper>
  );
};

export default Contact;
