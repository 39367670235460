import React from "react";
import "./trends.scss";
import ContentWrapper from "../contentWrapper/ContentWrapper";
import trendsBackground from "../../imgs/backgroundTrends.svg";
import trendsLine from "../../imgs/trendsLine.svg";
import instagramVector from "../../icons/instagramVector.svg";
import Button from "../button/Button";
import Selector from "../../redux/Selector";
import API from "../../redux/API";

const Trends = () => {
  const trendsData = Selector();

  return (
    <ContentWrapper>
      <div className="trends">
        <div className="trends__wrapper">
          <div className="trends__imgs__wrapper">
            <div className="trends__title title">Сучасні тренди</div>
            {trendsData.trends &&
              trendsData.trends.map((item, index) => {
                return (
                  <div className={"trends__item" + index} key={index}>
                    <div className={"trends__text" + index}>
                      <div className={"trends__text__title item_title"}>
                        {item.title}
                      </div>
                      <div
                        className={
                          "item_description trends__text__description" + index
                        }
                      >
                        {item.description}
                      </div>
                      <div className={`trends__text__price trends_text_price_decorator${index}`}>{item.cost}</div>
                      <img className="trends__line" src={trendsLine} alt="лінія" />
                    </div>
                    <div className={`trends__picture__wrapper`}>
                      <img
                      className={"trends__picture" + index}
                        src={API + item.url}
                        alt={`тренд ${index}`}
                      />
                    </div>
                  </div>
                );
              })}
            <a
              className="more__trends"
              href={trendsData.contacts ? trendsData.contacts.instagram : null}
            >
              <p className="more__trends__text item_description">
                більше трендів
              </p>
              <img className="more__trends__logo" src={instagramVector} alt="більше трендів" />
            </a>
          </div>
        </div>
        <div className="trends__button">
          <Button click="openModal" text="Виклик майстра" />
        </div>
        <img className="trends__background" src={trendsBackground} alt="фон" />
      </div>
    </ContentWrapper>
  );
};

export default Trends;
