import React from "react";

import PageWrapper from "../../../components/Admin/PageWrapper/PageWrapper";
import TrendsForm from "../../../components/Admin/TrendsForm/TrendsForm";

const TrendsAdmin = () => {
  return (
    <PageWrapper title={"Сучасні тренди"}>
      <TrendsForm />
    </PageWrapper>
  );
};

export default TrendsAdmin;
