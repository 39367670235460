import React from "react";
import "./button.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/dataSlice";
import { useHistory } from "react-router-dom";

const Button = ({ click, text, type, state, styles }) => {

  let history = useHistory();

  function handleClick() {
    if (click === "openModal") {
      dispatch(openModal());
    } else if (click === "submitedModal") {
      state(false);
      dispatch(openModal());
    } else if (click === "goToMain") {
      history.push("/");
    }
  }

  const dispatch = useDispatch();

  return (
    <div>
      <button
        className={`button button_text ${styles}`}
        type={type}
        onClick={() => handleClick()}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
