import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../../redux/adminSlices/loginSlice";
import { getLoginValid } from "../../../redux/selectors/adminSelectors";
import {
  defaultLoginForm,
  loginFormValidation,
} from "../../../validation/loginFormValidation";
import styles from "./LoginForm.module.scss";

export const LoginForm = () => {
  const isValidData = useSelector(getLoginValid);
  const dispatch = useDispatch();
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginWrapper}>
        <div className={styles.loginHeading}>
          <h3>Admin panel</h3>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultLoginForm }}
          validationSchema={loginFormValidation}
          onSubmit={(values) => {
            dispatch(fetchLoginRequest(values));
          }}
        >
          {() => (
            <Form className={styles.formWrapper}>
              <div className={styles.inputWrapper}>
                <Field
                  className={styles.input}
                  name="login"
                  type="text"
                  placeholder="Login *"
                />
              </div>
              <div className={styles.inputWrapper}>
                <Field
                  className={styles.input}
                  name="password"
                  type="password"
                  placeholder="Password *"
                />
              </div>
              {isValidData && (
                <div className={styles.warning}>{"Помилка данних!"}</div>
              )}

              <div className={styles.buttonWrapper}>
                <button type="submit">{"Увійти"}</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
