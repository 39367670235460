import React, { useEffect, useState } from "react";
import "./modal.scss";
import ReactDom from "react-dom";
import closeModal from "../../icons/closeModal.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/dataSlice";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import Button from "../button/Button";
import validate from "./validation";
import { sendModalData } from "../../redux/API";

const Modal = () => {
  const isOpen = useSelector((state) => state.data.isModalOpen);
  const dispatch = useDispatch();

  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    document.querySelector("body").style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDom.createPortal(
    <div className="modal__wrapper">
      <div className="modal">
        <h1 className="modal__title item_title">Виклик майстра</h1>
        <Formik
          initialValues={{
            name: "",
            phone: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            values.phoneNumber = values.phone;
            delete values.phone;
            sendModalData(values);
            setIsSubmited(true);
          }}
        >
          {!isSubmited ? ( ({setFieldValue}) => 
            <Form>
              <div className="form">
                <TextField name="name" type="text" placeholder="Ім’я *" autoComplete/>
                <TextField
                  name="phone"
                  id="phone"
                  type="tel"
                  placeholder="Телефон *"
                  // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  autoComplete
                  onChange={e => {
                    e.preventDefault();
                    const { value } = e.target;
                    const regex = /^[\d(), ]+$/;
                    if (regex.test(value.toString())) {
                      setFieldValue("phone", value);
                    } else if (!value) {
                      setFieldValue("phone", "");
                    }
                  }}
                />
              </div>
              <Button
                text="Викликати"
                type="submit"
                styles="modal_submit_btn"
              />
            </Form>
          ) : (
            <div className={`submited__form__text`}>
              <div className="submited__form__text1">Дякуємо за довіру )</div>
              <div className="submited__form__text2">
                Ми зв’яжемося з вами найближчим часом.
              </div>
              <Button
                text="На головну"
                type="submit"
                click="submitedModal"
                state={setIsSubmited}
                styles="modal_submit_btn"
              />
            </div>
          )}
        </Formik>
        <button className="close__modal" onClick={() => dispatch(openModal())}>
          <img src={closeModal} alt="закрити модальне вікно" />
        </button>
      </div>
      <div className="bg__layer" onClick={() => dispatch(openModal())}></div>
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;
