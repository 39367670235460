import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openAdminModal,
  patchMainBenefits,
} from "../../../../redux/adminSlices/modalSlice";
import { getMainBenefits } from "../../../../redux/selectors/adminSelectors";
import { aboutMainSchema } from "../../../../validation/mainValidation";
import Button from "../../Button/Button";
import FormikControl from "../../FormikContainer/FormikControl";
import Modal from "../../Modal/Modal";
import styles from "./MainBenefits.module.scss";

const MainBenefits = () => {
  const dispatch = useDispatch();
  const mainBenefits = useSelector(getMainBenefits);

  const submit = (values) => {
    dispatch(patchMainBenefits(values));
  };

  if (!mainBenefits) return null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={mainBenefits}
      validationSchema={aboutMainSchema}
      onSubmit={submit}
    >
      {(values) => (
        <Form>
          <div className={styles.formWrapper}>
            {mainBenefits &&
              mainBenefits.map((elem, idx) => (
                <div className={styles.blockWrapper} key={`${idx}`}>
                  <FormikControl
                    control="input"
                    type="text"
                    label={`Перевага ${idx + 1}`}
                    name={`${idx}.title`}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    name={`${idx}.description`}
                  />
                </div>
              ))}
          </div>
          <Button
            onClick={() => {
              dispatch(openAdminModal("benefits"));
            }}
            disabled={!values.dirty || !values.isValid}
            title={"Зберегти"}
          />
          <Modal title={"benefits"} />
        </Form>
      )}
    </Formik>
  );
};

export default MainBenefits;
