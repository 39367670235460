import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiAdmin } from "../../api/apiAdmin";

export const fetchLoginRequest = createAsyncThunk(
  "login/fetchLoginRequest",
  (values) => apiAdmin.loginRequest(values)
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: true,
    accessToken: "",
    incorrectData: false,
  },
  reducers: {
    logout(state) {
      state.accessToken = "";
    },
  },
  extraReducers: {
    [fetchLoginRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.accessToken = action.payload;
      state.incorrectData = false;
    },
    [fetchLoginRequest.rejected]: (state) => {
      state.loading = false;
      state.accessToken = "";
      state.incorrectData = true;
    },
  },
});
export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
