import { Form, Formik } from "formik";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Url } from "../../../api/apiAdmin";
import {
  openAdminModal,
  patchContactsRequest,
} from "../../../redux/adminSlices/modalSlice";

import { getContact } from "../../../redux/selectors/adminSelectors";
import { contactFormValidation } from "../../../validation/contactValidation";
import AddFile from "../AddFile/AddFile";
import Button from "../Button/Button";
import FormikControl from "../FormikContainer/FormikControl";
import Modal from "../Modal/Modal";
import styles from "./ContactForm.module.scss";

const ContactForm = () => {
  const dispatch = useDispatch();
  const contacts = useSelector(getContact);
  if (!contacts) return null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={contacts}
      validationSchema={contactFormValidation}
      onSubmit={(values) => {
        dispatch(patchContactsRequest(values));
      }}
    >
      {(values) => (
        <Form>
          <div className={styles.formWrapper}>
            <div className={styles.addressBlockWrapper}>
              <FormikControl
                control="input"
                type="text"
                label={`Адреса`}
                name={`address`}
              />
              <FormikControl
                control="input"
                type="phone"
                label={`Телефон`}
                name={`phoneNumber`}
              />
              <FormikControl
                control="input"
                type="text"
                label={`Ім’я`}
                name={`name`}
              />
            </div>
            <div className={styles.socialBlockWrapper}>
              <FormikControl
                control="input"
                type="text"
                label={`Посилання на Фейсбук`}
                name={`facebook`}
              />
              <FormikControl
                control="input"
                type="text"
                label={`Постлання на Інстраграм`}
                name={`instagram`}
              />
            </div>
            <div className={styles.mapBlockWrapper}>
              <AddFile
                status={"bigImg"}
                preview={Url + contacts.mapImage}
                big
                values={values}
                title={`Зображення карти`}
                name={`mapImage`}
                sizes={"Розмір 290х156 рх"}
              />
              <FormikControl
                control="input"
                type="text"
                label={`Посилання на GoogleMaps`}
                name={`mapLink`}
              />
            </div>
          </div>
          <Modal title={"contact"} />
          <Button
            onClick={() => {
              dispatch(openAdminModal("contact"));
            }}
            disabled={!values.dirty || !values.isValid}
            title={"Зберегти"}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
