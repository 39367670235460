export const route = {
  admin: "/admin",
  client: "/",
};

export const adminRoute = {
  Start: "/admin",
  HowWeWorks: "/admin/how-we-works",
  FutureTrends: "/admin/future-trends",
  WhyWeAre: "/admin/why-we-are",
  Contacts: "/admin/contacts",
};

export const sidebarLinks = [
  {
    name: "Головна",
    link: adminRoute.Start,
  },
  {
    name: "Як ми працюємо",
    link: adminRoute.HowWeWorks,
  },
  {
    name: "Сучасні тренди",
    link: adminRoute.FutureTrends,
  },
  {
    name: "Чому саме ми",
    link: adminRoute.WhyWeAre,
  },
  {
    name: "Контакти і соцмережі",
    link: adminRoute.Contacts,
  },
];
