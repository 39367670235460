import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./Formik.module.scss";

function Textarea(props) {
  const { label, name, ...rest } = props;
  return (
    <div className={styles.inputWrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field
        className={styles.textarea}
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
  );
}

export default Textarea;
