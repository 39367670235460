import React from "react";
import NotFound from "../components/404/NotFound";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

function PageNotFound() {
  return (
    <div className="App">
      <Header />
      <NotFound />
      <Footer />
    </div>
  );
}

export default PageNotFound;
