import React from "react";
import HowWeWorksForm from "../../../components/Admin/HowWeWorksForm/HowWeWorksForm";
import PageWrapper from "../../../components/Admin/PageWrapper/PageWrapper";

const HowWeWorks = () => {
  return (
    <PageWrapper title={"Як ми працюємо"}>
      <HowWeWorksForm />
    </PageWrapper>
  );
};

export default HowWeWorks;
