import React, { useEffect, useState } from "react";
import "./header.scss";
import ContentWrapper from "../contentWrapper/ContentWrapper";
import instagramIcon from "../../icons/instagram.svg";
import facebookIcon from "../../icons/facebook.svg";
import logo from "../../icons/logo.svg";
import phone from '../../icons/phone.svg';

import Selector from "../../redux/Selector";
import { useSelector } from "react-redux";

const Header = () => {
  const headerData = Selector().contacts;
  const isOpen = useSelector((state) => state.data.isModalOpen);

  const [headerPosition, setHeaderPosition] = useState(true);

  const handleNavigation = (y, headerPosition) => () => {
    if (window.scrollY < 20) return;
    if (y > window.scrollY && headerPosition !== true) {
      setHeaderPosition(true);
      headerPosition = true;
    } else if (y < window.scrollY && headerPosition !== false) {
      setHeaderPosition(false);
      headerPosition = false;
    }
    y = window.scrollY;
  };

  useEffect(() => {
    const handleNavigationWorker = handleNavigation(
      window.scrollY,
      headerPosition
    );
    window.addEventListener("scroll", handleNavigationWorker);

    return () => {
      window.removeEventListener("scroll", handleNavigationWorker);
    };
  }, [headerPosition]);

  return (
    <div
      className={`header ${isOpen ? "header_hide" : `${headerPosition ? "header_fixed" : "header_hide"}`}`}
    >
      <div className="header__main__wrapper">
        <ContentWrapper>
          <div className="header__wrapper">
            <div className="header__icons">
              <a
                className="icon instagram hover_class"
                href={headerData ? headerData.instagram : null}
              >
                <img src={instagramIcon} alt="іконка інстаграм" />
              </a>
              <a
                className="icon facebook hover_class"
                href={headerData ? headerData.facebook : null}
              >
                <img src={facebookIcon} alt="іконка фейсбук" />
              </a>
            </div>
            <div className="logo hover_class">
              <img src={logo} alt="логотип" />
            </div>
            <a
              href="tel:+38 068 69 24 618"
              className="phone__number hover_class"
            >
              {headerData ? headerData.phoneNumber : null}
            </a>
            <a
              className="header__phone__icon__wrapper"
              href={`tel: ${headerData ? headerData.phoneNumber : null}`}
            >
              <img
                src={phone}
                className="header__phone__icon"
                alt="іконка телефону"
              />
            </a>
          </div>
        </ContentWrapper>
      </div>
      <div className="under__header" />
    </div>
  );
};

export default Header;