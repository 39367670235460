import * as Yup from "yup";

export const aboutSchema = Yup.array().of(
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(50, "Максимальне число символів 50"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(300, "Максимальне число символів 300"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(50, "Максимальне число символів 50"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(300, "Максимальне число символів 300"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(50, "Максимальне число символів 50"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(300, "Максимальне число символів 300"),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Заповніть дане поле*")
      .max(50, "Максимальне число символів 50"),
    description: Yup.string()
      .required("Заповніть дане поле*")
      .max(300, "Максимальне число символів 300"),
  })
);
