import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./Formik.module.scss";

function Input(props) {
  const { label, name, ...rest } = props;
  return (
    <div className={styles.inputWrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field className={styles.input} id={name} name={name} {...rest} />
      <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
  );
}

export default Input;
