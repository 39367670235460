import React from "react";

import styles from "./Button.module.scss";

const Button = (props) => {
  const btnHandler = (e) => {
    if (props.onClick) {
      e.preventDefault();
      props.onClick();
    }
    // props.onSubmit && props.onSubmit();
  };
  return (
    <div className={styles.buttonWrapper}>
      <button
        onClick={btnHandler}
        id={props.id}
        disabled={props.disabled ? true : false}
        type={props.type}
        className={
          props.red
            ? styles.buttonRed
            : props.disabled
            ? styles.disabled
            : styles.button
        }
      >
        {props.title}
      </button>
    </div>
  );
};

export default Button;
